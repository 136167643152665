#print-work-order .map-print > img {
  max-width: 100% !important;
}
@media (max-width: 435px) {

  #print_work_order {
    width: 90vw;
    padding: 0!important;
  }

  #print_work_order .fontSize8 h6 {
    font-size: 8px!important;
  }

  .dott-overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  #print_work_order .mobile-text-left {
    text-align: left!important;
  }

  #print_work_order .pad-left {
    padding: 0!important;
  }
}
.photo-image > img {
  max-width: 90vw ;
}

@media print {
  #print_work_order, body {
    margin: 0;
    padding: 0;
    width: 100%
  }
}