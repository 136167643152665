#invoices .react-bs-table {
    max-height: calc(100vh - 230px) !important;
    height: auto !important;
    overflow: auto !important;
}

#invoice-filter-modal .modal-dialog > div {
    height: 40vh !important;
}

@media(max-width: 1390px) {
    #invoices .react-bs-table {
        max-height: calc(100vh - 260px) !important;
        height: auto !important;
        overflow: auto !important;
    }
}

@media(max-width: 1570px) {
    .InvoicePanelLR {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media(max-height: 1000px) {
  #invoice-filter-modal .modal-dialog > div {
        height: 65vh !important;
    }
}

#invoices .react-bs-table-pagination {
    margin-bottom: 10px !important;
}

#invoices .react-bs-table-pagination > div > div:nth-child(2) > ul {
    margin: 0 !important;
}

#invoices .vertical-responsive-height {
    height: calc(100vh - 135px);
}

div.justify-flex-end.col-xs-8 > div {
    width: 15%;
}

.modern-view {
    float: right !important;
    margin-top: -25px;
    margin-bottom: 10px;
}

@media(max-width: 1024px){
    .pagination {
        display: flex !important;
    }
    #invoices .vertical-responsive-height {
        height: calc(100vh - (-30px)) !important;
    }
    #invoices .link-no-padding > ul > li > a {
        padding: 10px 15px 10px 15px !important;
    }
}

.link-no-padding > ul > li > a {
    padding: 0 !important;
}

.nmargin {
    margin-top: -10px;
    margin-left: -15px;
}

.marginLeft {
    margin-left: 4px;
}

.headerBorder {
    border-bottom: 1px solid #dddddd;
}

.table {
    height: 100% !important;
}

#invoices {
    .pay-flex-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
    }

    .margin-inline-15 {
        margin-inline: 15px !important;
    }

    #invoices-toolbar {
        .export-email-buttons {
            flex: 2;
        }

        .radio-buttons {
            flex: 1.5;
        }

        .label-buttons {
            flex: 3;
        }

        .due-date-paid-at-buttons {
            flex: 1.5;
        }

        .update-button-and-input {
            .update-paid-button {
                max-height: 37px;
            }

            flex: 2.2;
        }
    }

    .settings-dropdown-menu {
        .dropdown-menu {
            min-width: 120px;
        }
    }

    .react-bs-container-header.table-header-wrapper {
        position: absolute;
        width: calc(100vw - 36px);
        background-color: white;
        z-index: 1;
    }

    .react-bs-table {
        max-height: calc(100vh - 250px) !important;
        height: auto !important;
        overflow: auto !important;
    }

    .react-bs-container-body {
        margin-top: 40px;
    }
}