.modal-datetime.rdtOpen > div {
    position: fixed;
    right: inherit;
}

.invalid{
    border-color: #FF0000;
}

.edited-row {
    border: solid 3px #1b6eb6;
}

.term-modal-input {
    margin-top: 1.35em
}

.validation-error {
    border: 2px solid #f16b6e!important;
}


@media (max-height: 900px) {
    #term_modal .modal-dialog > div {
        height: 78vh !important;
        overflow: hidden !important;
    }
}

@media (max-height: 850px) {
    #term_modal .modal-dialog > div {
        height: 80vh !important;
        overflow: hidden !important;
    }
}

@media (max-height: 750px) {
    #term_modal .modal-dialog > div {
        height: 88vh !important;
        overflow: hidden !important;
    }
}

@media (max-width: 480px) {
    #term_modal .modal-dialog > div {
        height: 90vh !important;
    }
}

@media (min-width: 1024px) and (max-height: 700px) {
    #term_modal .modal-dialog > div {
        height: 94vh !important;
        overflow: hidden !important;
    }
}