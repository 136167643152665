#customer-payment-modal .modal-content {
    height: unset!important;
}

.CardDemo {
    margin-top: 24px;
    width: 100%;
}
.CardDemo .security{
    text-align: left;
    margin-top: 10px;
}
.CardDemo .security-text{
    margin-top: 10px;
    font-style: italic;
}
.Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
}

.CardDemo label {
    align-content: center;
    width: 100%;
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
}

.CardDemo button {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    background-color: #6772e5;
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    margin-top: 10px;
}

.CardDemo form {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid #e6ebf1;
}

.CardDemo button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
}
button:disabled,
button[disabled]{
    opacity: 0.65;
}
.CardDemo input,
.StripeElement {
    display: block;
    margin: 10px auto 20px auto;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}

.CardDemo input::placeholder {
    color: #aab7c4;
}

.CardDemo input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

div[disabled]
{
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.7;
}
.stripe-options {
    width: 100%;
}
.stripe-options .card-options {
    width: 100%;
    justify-content: space-between;
}

.card-options:has(.save-card:only-child)  {
    justify-content: end !important;
}

.save-card {
    justify-self: end !important;
    align-self: end !important;
}
.options-container {
    width: 100%;
}

.stripe-options .input-group {
    width: 200px;
}