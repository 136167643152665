#new-sales-dashboard {
  $draft-color: rgba(146, 146, 146);
  $draft-color-opacity: rgba(146, 146, 146, 0.2);
  $sent-color: rgba(0, 137, 21);
  $sent-color-opacity: rgba(0, 137, 21, 0.2);
  $backlog-color: rgba(0, 119, 215);
  $backlog-color-opacity: rgba(0, 119, 215, 0.2);
  $completed-color: rgba(255, 87, 7);
  $completed-color-opacity: rgba(255, 87, 7, 0.2);
  $invoiced-color: rgba(229, 29, 60);
  $invoiced-color-opacity: rgba(229, 29, 60, 0.2);
  $paid-color: rgba(94, 94, 94);
  $paid-color-opacity: rgba(94, 94, 94, 0.2);

  margin: 0;

  .summary-row {
    .summary-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      cursor: pointer;
    }

    .draft-tab {
      background-color: $draft-color-opacity;
      color: black;
      transition: all 0.3s;
      box-shadow: inset 1px 1px 10px rgba(232, 232, 232, 0.4), inset -1px -1px 10px rgba(232, 232, 232, 0.4);
    }
    .active-draft-tab {
      background-color: $draft-color;
      transition: all 0.3s;
      box-shadow: 2px 0 5px gray, -2px 0 5px gray;
    }

    .sent-tab {
      background-color: $sent-color-opacity;
      color: black;
      transition: all 0.3s;
      box-shadow: inset 1px 1px 10px rgba(232, 232, 232, 0.4), inset -1px -1px 10px rgba(232, 232, 232, 0.4);
    }
    .active-sent-tab {
      background-color: $sent-color;
      transition: all 0.3s;
      box-shadow: 2px 0 5px gray, -2px 0 5px gray;
    }

    .backlog-tab {
      background-color: $backlog-color-opacity;
      color: black;
      transition: all 0.3s;
      box-shadow: inset 1px 1px 10px rgba(232, 232, 232, 0.4), inset -1px -1px 10px rgba(232, 232, 232, 0.4);
    }
    .active-backlog-tab {
      background-color: $backlog-color;
      transition: all 0.3s;
      box-shadow: 2px 0 5px gray, -2px 0 5px gray;
    }

    .completed-tab {
      background-color: $completed-color-opacity;
      color: black;
      transition: all 0.3s;
      box-shadow: inset 1px 1px 10px rgba(232, 232, 232, 0.4), inset -1px -1px 10px rgba(232, 232, 232, 0.4);
    }
    .active-completed-tab {
      background-color: $completed-color;
      transition: all 0.3s;
      box-shadow: 2px 0 5px gray, -2px 0 5px gray;
    }

    .invoiced-tab {
      background-color: $invoiced-color-opacity;
      color: black;
      transition: all 0.3s;
      box-shadow: inset 1px 1px 10px rgba(232, 232, 232, 0.4), inset -1px -1px 10px rgba(232, 232, 232, 0.4);
    }
    .active-invoiced-tab {
      background-color: $invoiced-color;
      transition: all 0.3s;
      box-shadow: 2px 0 5px gray, -2px 0 5px gray;
    }

    .paid-tab {
      background-color: $paid-color-opacity;
      color: black;
      transition: all 0.3s;
      box-shadow: inset 1px 1px 10px rgba(232, 232, 232, 0.4), inset -1px -1px 10px rgba(232, 232, 232, 0.4);
    }
    .active-paid-tab {
      background-color: $paid-color;
      transition: all 0.3s;
      box-shadow: 2px 0 5px gray, -2px 0 5px gray;
    }
  }

  .table-wrapper {
    overflow-x: hidden;
    padding: 0;
    height: calc(100vh - 165px) !important;

    .react-bs-table-pagination {
      position: absolute;
      width: -webkit-fill-available;
      margin-top: 0;

      .react-bootstrap-table-page-btns-ul {
        margin: 0;
      }
    }

    .react-bs-container-body {
      height: auto !important;
    }

    .table-striped {
      overflow-y: scroll;
    }

    .react-bs-table {
      height: calc(100vh - 275px) !important;
      overflow-y: scroll;
    }

    [class$="-table"]-select-table {
      height: calc(100vh - 285px) !important;
      overflow-y: scroll;
    }

    .draft-select-table {
      border: solid 3px $draft-color;
      transition: all 0.3s;
      padding-bottom: 0;
    }

    .sent-select-table {
      border: solid 3px $sent-color;
      transition: all 0.3s;
    }

    .backlog-select-table {
      border: solid 3px $backlog-color;
      transition: all 0.3s;
    }

    .completed-select-table {
      border: solid 3px $completed-color;
      transition: all 0.3s;
    }

    .invoiced-select-table {
      border: solid 3px $invoiced-color;
      transition: all 0.3s;
    }

    .paid-select-table {
      border: solid 3px $paid-color;
      transition: all 0.3s;
    }
  }

  .buttons-wrapper {
    margin: 5px 0;
  }
}

#filter-sales-dashboard-id {
  .modal-content {
    height: auto !important;
    max-height: 500px;
  }

  .select-wrapper {
    margin-bottom: 5px;
  }

  .date-picker-wrapper {
    margin-bottom: 5px;
  }

  .control-label {
    color: black;
  }
}

@media (max-width: 1236px) {
  #new-sales-dashboard {
    .table-wrapper {
      height: calc(100vh - 210px) !important;

      .react-bs-table {
        height: calc(100vh - 320px) !important;
      }

      [class$="-table"]-select-table {
        height: calc(100vh - 330px) !important;
      }
    }
  }
}

#sales_dashboard_popover {
  .popover-content {
    max-height: 170px;
    overflow: scroll;
  }
}