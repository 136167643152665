#invoice-filter-modal {
  .modal-content {
    height: 80vh !important;
  }

  @media (max-width: 768px) {
    .modal-content {
      height: 90vh !important;
    }
  }

  .modal-body {
    margin-top: 0 !important;
  }

  .mt-12-ml-16 {
    margin-top: 12px !important;
    margin-left: 16px !important;
  }
}
